import React from 'react';
import Card from '@mui/material/Card';
import './App.css';
import gymPicture from './gympic.png';
import code from './code.png';
import master from'./master.png';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import {CardActionArea, Typography} from '@mui/material';
import Grid from "@mui/material/Grid";




function App() {
  return (
    <div className="Main">
      <div className="Name">
        JEFFREY VAN</div>
      <div className="Grid">
      <Grid
          container
          spacing="2"
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
      >
        <Card sx = {{maxWidth: 345}}>
          <CardActionArea style={{backgroundColor:'black'}}>
            <CardMedia
                component= "img"
                height= "250"
                image={code}
                alt="coding"
            />
            <CardContent>
              <Typography gutterBottom variant = "h5" component="div" color ='white'>
                  Coding
              </Typography>
              <Typography variant = "body2" color ='white'>
                I love problem solving. Coding and making usable software that solves
                day to day problems is one of the things I enjoy doing.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card sx = {{maxWidth: 345}}>
          <CardActionArea style={{backgroundColor:'black'}}>
            <CardMedia
                component= "img"
                height= "250"
                image={gymPicture}
                alt="gym picture"
            />
            <CardContent>
              <Typography gutterBottom variant = "h5" component="div" color ='white'>
                Powerlifting
              </Typography>
              <Typography variant = "body2" color ='white'>
                I love going to the gym. Powerlifting is one of my favorite hobbies in the world. Discipline, hardwork,
                dedication, passion, and love are things I channel into this sport.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card sx = {{maxWidth: 345}}>
          <CardActionArea style={{backgroundColor:'black'}}>
            <CardMedia
                component= "img"
                height= "250"
                image={master}
                alt="league"
            />
            <CardContent>
              <Typography gutterBottom variant = "h5" component="div" color ='white'>
                Gaming
              </Typography>
              <Typography variant = "body2" color ='white'>
                Gaming is one of my other past-time hobbies. A part of gaming is problem solving,
                and it is tremendously fun to see improvement in skill.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
    </Grid>
    </div>
    </div>
  );
}

export default App;
